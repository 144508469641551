import React from 'react'

export default class Army extends React.Component {

    render() {

		return(
			<section className='army block'>
				<div className='army-container container'>
					<p className='text infantry-text'>I am a proud<br />U.S. Army combat<br />infantry veteran.</p>
                    <div className='learnmore'><a className='army-learnmore-btn' href='javascript:void(0)'>Learn More</a></div>
                    <p className='text unit-text'>Charlie Company <br />1st Battalion, 9th Cavalry Regiment<br />Operation Iraqi Freedom II</p>
				</div>
			</section>
		)
	}
}