import React from 'react'
import Venn from './Venn'

export default class Intro extends React.Component {

	render() {

		return(
			<section className="intro block">
				<div className='container'>
                    <h2 className='greeting'>Hello</h2>
					<p className='intro-text text'>I am a product leader who thrives at the intersection of <strong>business</strong>, <strong>technology</strong>, <strong>design</strong>, and the <strong>human&nbsp;experience</strong>.</p>
					<Venn />
				</div>
			</section>
		)
	}
}