import React from 'react'
import brands from '../img/brands.svg'
import brandSamsung from '../img/brand-samsung.svg'
import brandHarman from '../img/brand-harman.svg'
import brandJBL from '../img/brand-jbl.svg'
import brandAMX from '../img/brand-amx.svg'
import brandBSS from '../img/brand-bss.svg'
import brandCrown from '../img/brand-crown.svg'
import brandDelve from '../img/brand-delve.svg'
import brandBD from '../img/brand-bd.svg'
import brandRoche from '../img/brand-roche.svg'
import brandFellowes from '../img/brand-fellowes.svg'

export default class Brands extends React.Component {

	render() {

		return(
			<section className='brands block'>
				<div className='brands-container container'>
                    <p className='brands-text text'>I've helped develop products for some of the most incredible brands.</p>
					<div className='brand-images'>
                        <img className='brands-img brand-samsung' src={brandSamsung} alt="Samsung logo" />
                        <img className='brands-img brand-harman' src={brandHarman} alt="HARMAN logo" />
                        <img className='brands-img brand-JBL' src={brandJBL} alt="JBL logo" />
                        <img className='brands-img brand-AMX' src={brandAMX} alt="AMX logo" />
                        <img className='brands-img brand-BSS' src={brandBSS} alt="BSS logo" />
                        <img className='brands-img brand-crown' src={brandCrown} alt="Crown logo" />
                        <img className='brands-img brand-delve' src={brandDelve} alt="Delve logo" />
                        <img className='brands-img brand-bd' src={brandBD} alt="BD logo" />
                        <img className='brands-img brand-roche' src={brandRoche} alt="Roche logo" />
                        <img className='brands-img brand-fellowes' src={brandFellowes} alt="Fellowes logo" />
                    </div>

				</div>
			</section>
		)
	}
}