// import logo from './logo.svg';
import './css/App.css'
import Header from './components/Header'
import Intro from './components/Intro'
import Recent from './components/Recent'
import Brands from './components/Brands'
import Army from './components/Army'
import Volunteer from './components/Volunteer'
import Contact from './components/Contact'

function App() {
    return (
        <div className="App">
            <Header />
            <Intro />
            <Brands />
            <Recent />
            <Army />
            <Volunteer />
            <Contact />
        </div>
    );
}

export default App;
