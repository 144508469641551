import React from 'react'
import mst from '../img/mst.svg'
import matt from '../img/matt.png'

export default class Header extends React.Component {

	render() {

		return(
			<header>
				<div className='header-container container'>
					<img className='matt' src={matt} alt="Matt" />
					<img className='mst' src={mst} alt="Matthew Sean Thomas" />
				</div>
			</header>
		)
	}
}