import React from 'react'
import twklogo from '../img/twk-logo.svg'
import ctllogo from '../img/ctl-logo.svg'

export default class Header extends React.Component {

    render() {

		return(
			<section className='volunteer block'>
                <div className='twk volunteer-block'>
                    <div>
                        <p className='supporter'>Which also makes me an ardent supporter for those who served.</p>
                        <p className='volunteer-stmnt'>I serve on the board of <strong>The Warrior's Keep</strong>, whose mission is to provide outdoor adventure therapy to at-risk veterans.</p>
                        <a className='learnmore-link' href="https://www.thewarriorskeep.org/" target='_blank' rel='noopener noreferrer'>Learn more</a>
                    </div>
                </div>
                <div className='twk-img volunteer-block'>
                    <img className='twk-logo' src={twklogo} alt="The Warrior's Keep Logo" />
                </div>
                <div className='ctl volunteer-block'>
                    <div>
                        <p className='supporter'>Remembering those who gave all.</p>
                        <p className='volunteer-stmnt'>I am also an Ambassador for <strong>Carry the Load</strong>, whose mission is to restore the true meaning of Memorial Day.</p>
                        <a className='learnmore-link' href="https://www.carrytheload.org/" target='_blank' rel='noopener noreferrer'>Learn more</a>
                    </div>
                </div>
                <div className='ctl-img volunteer-block'>
                    <img className='ctl-logo' src={ctllogo} alt="Carry the Load logo" />
                </div>
            </section>
		)
	}
}