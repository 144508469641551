import React, { useEffect, useRef } from 'react'
import venn from '../img/venn5.svg'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP)


export default function Venn() {

    const container = useRef()
    const t1 = useRef()

    useGSAP (
        () => {
            t1.current = gsap.to('.venn', {
                rotation: "+=360",
                ease: 'none',
                duration: 10,
                repeat: -1
            })
        }
    )
	
	
	// useEffect(() => {
		
	// 	vennRotation = gsap.to(vennRef.current, {
	// 		rotation: "+=360",
	// 		ease: 'none',
	// 		duration: 10,
	// 		repeat: -1
	// 	})
		
	// 	vennRotation.play()

	// },[])

	function increaseSpeed(e){
		t1.current.timeScale(20)
		e.preventDefault()
		e.stopPropagation()
	}

	function decreaseSpeed(e){
		t1.current.timeScale(1)
		e.preventDefault()
		e.stopPropagation()
	}

	function handleMouseDown(e){
		e.preventDefault()
		e.stopPropagation()
		return false
	}

	return <div className='venn-container' ref={container}><img className='venn' onMouseDown={handleMouseDown} onTouchStart={increaseSpeed} onTouchEnd={decreaseSpeed} onMouseEnter={increaseSpeed} onMouseLeave={decreaseSpeed} src={venn} alt="Business, Tech, Design, People" /></div>
}