import React, { useRef } from 'react'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
const { useEffect, useState } = React

gsap.registerPlugin(useGSAP)


function Contact() {

    var isMobile = false
    var contactWidth = "800px"

    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) { isMobile = true }
    
    if( isMobile ) {
        contactWidth = "83.5%"
    }

    const container = useRef()
    const t1 = useRef()

    const toggleTimeline = () => {
        t1.current.reversed(!t1.current.reversed())
    };

    useGSAP(() => {

        t1.current = gsap.timeline()
            .to(".contact",{
                width: contactWidth,
                height: "72px",
                duration: 0.3
            }).reverse()
    },{ scope: container })

    function onTouchContact(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    return(
        <section ref={container}>
            <div className='contact'>
                <h2 onMouseDown={toggleTimeline}>Wanna work together or just geek out?</h2>
                <p>Connect or message me: <a href='https://www.linkedin.com/in/matthewseanthomas' target='_blank' rel="noreferrer">https://www.linkedin.com/in/matthewseanthomas</a></p>
            </div>
        </section>
    )
}

export default Contact 
