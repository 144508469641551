import React from 'react'
import harmanlogo from '../img/harman-logo.svg'

export default class Recent extends React.Component {
    render() {

		return(
			<section className='recent block'>
				<div className='recent-container container'>
                <p className='harman-text'>Most recently, I helped build a <strong>suite of A/V software</strong> <br />for HARMAN Pro Solutions &mdash; enabling rich experiences while <strong>driving growth</strong> for multiple product lines.</p>
                    <img className="harman-logo" src={harmanlogo} alt="HARMAN: A Samsung Company" />
				</div>
			</section>
		)
	}
}